import { Link } from '../components/Link';

export default function TermsPage() {
  return (
    <div className="pt-20 bg-[#1f2937]">
      {/* Header Section */}
      <div className="bg-[#1a222e] text-white py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 className="text-4xl font-bold mb-6">Terms and Conditions</h1>
          <p className="text-xl text-gray-300">
            Please read these terms carefully before using our services.
          </p>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <div className="text-gray-200 max-w-4xl mx-auto">
          <section className="mb-12 bg-gray-800/50 p-8 rounded-lg">
            <h2 className="text-2xl font-bold text-white mb-4">Agreement</h2>
            <p className="text-lg leading-relaxed">
              These terms and conditions govern your use of the DSTV Installation Sandton website. 
              By accessing our website, you agree to these terms.
            </p>
          </section>

          <section className="mb-12 bg-gray-800/50 p-8 rounded-lg">
            <h2 className="text-2xl font-bold text-white mb-4">Services</h2>
            <p className="text-lg leading-relaxed">
              We connect homeowners in Sandton and surrounding areas with accredited DSTV installers 
              for repairs, upgrades, and installations.
            </p>
          </section>

          <section className="mb-12 bg-gray-800/50 p-8 rounded-lg">
            <h2 className="text-2xl font-bold text-white mb-4">Disclaimer and Limitation of Liability</h2>
            <p className="text-lg leading-relaxed mb-4">
              While we screen our installers, we act as a facilitator only. Any disputes with 
              installers should be addressed directly with them. We are not liable for the 
              workmanship of individual installers.
            </p>
            <p className="text-lg leading-relaxed mb-4">
              The website owner, operators, and administrators bear no responsibility or liability whatsoever for:
            </p>
            <ul className="list-disc pl-8 space-y-2 text-lg">
              <li>Any damage to property, equipment, or personal belongings during installation or repair services</li>
              <li>Theft, loss, or misconduct by any third-party service providers</li>
              <li>Accuracy of information, advice, or recommendations provided on this website</li>
              <li>Quality of workmanship or services provided by installers</li>
              <li>Any direct, indirect, incidental, consequential, or punitive damages arising from the use of this website or the services arranged through it</li>
              <li>Any disputes, disagreements, or financial matters between users and service providers</li>
            </ul>
            <p className="mt-6 text-lg leading-relaxed">
              By using this website, you explicitly agree to hold harmless and release the website owner from any and all claims, 
              demands, damages, and liabilities of any nature whatsoever.
            </p>
          </section>

          <section className="mb-12 bg-gray-800/50 p-8 rounded-lg">
            <h2 className="text-2xl font-bold text-white mb-4">Privacy and Data Protection</h2>
            <p className="text-lg leading-relaxed">
              We protect your personal information in accordance with our{' '}
              <Link href="/privacy-policy" className="text-pink-400 hover:text-pink-300 font-medium">
                Privacy Policy
              </Link>{' '}
              and South African law.
            </p>
          </section>

          <section className="bg-gray-800/50 p-8 rounded-lg">
            <h2 className="text-2xl font-bold text-white mb-4">Contact Information</h2>
            <p className="text-lg leading-relaxed">
              For questions about these terms, contact us at{' '}
              <Link href="mailto:info@dstvinstallationsandton.co.za" className="text-pink-400 hover:text-pink-300 font-medium">
                info@dstvinstallationsandton.co.za
              </Link>
            </p>
          </section>
        </div>
      </div>
    </div>
  );
} 