import { Menu, Phone, X, ChevronDown } from 'lucide-react';
import { useState } from 'react';
import { Link } from './Link';

export default function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const [isServicesOpen, setIsServicesOpen] = useState(false);

  const serviceLinks = [
    { href: "/extra-view", label: "Extra View" },
    { href: "/maintenance", label: "Maintenance" },
    { href: "/tv-installation", label: "TV Installation" },
    { href: "/cctv", label: "CCTV" },
    { href: "/commercial", label: "Commercial" },
    { href: "/troubleshooting", label: "Troubleshooting" },
  ];

  const handleMobileNavClick = () => {
    setIsOpen(false);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <nav className="bg-[#0f172a] text-white fixed w-full z-50 top-0 shadow-lg shadow-black/10">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-20">
          {/* Logo */}
          <div className="flex-shrink-0">
            <Link href="/">
              <img 
                src="/images/DSTV-PRO-INSTALLATION-LOGO-1 (2).png" 
                alt="DSTV Pro Installations Logo" 
                className="h-12 w-auto"
              />
            </Link>
          </div>

          {/* Emergency Contact */}
          <div className="hidden md:flex items-center">
            <a href="tel:+27787294594" className="flex items-center space-x-2 text-pink-500 hover:text-pink-400 transition-colors">
              <Phone className="h-5 w-5" />
              <span className="font-semibold">+27 78 729 4594</span>
            </a>
          </div>

          {/* Desktop Navigation */}
          <div className="hidden md:flex items-center space-x-4">
            <div className="relative group">
              <button 
                className="flex items-center space-x-1 hover:text-pink-400 focus:outline-none"
                onClick={() => setIsServicesOpen(!isServicesOpen)}
              >
                <span>Services</span>
                <ChevronDown className="h-4 w-4" />
              </button>
              
              <div className="absolute left-0 mt-2 w-48 rounded-md shadow-lg bg-[#1a222e] opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-200 transform origin-top-right">
                <div className="py-1">
                  {serviceLinks.map((link) => (
                    <Link
                      key={link.href}
                      href={link.href}
                      className="block px-4 py-2 text-sm hover:bg-[#2d3748]"
                    >
                      {link.label}
                    </Link>
                  ))}
                </div>
              </div>
            </div>

            <Link href="/contact" className="bg-pink-500 hover:bg-pink-600 px-4 py-2 rounded-full">
              Book Now
            </Link>
          </div>

          {/* Mobile menu button */}
          <div className="md:hidden flex items-center space-x-4">
            <a href="tel:+27787294594" className="text-pink-500 hover:text-pink-400">
              <Phone className="h-5 w-5" />
            </a>
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="inline-flex items-center justify-center p-2 rounded-md hover:bg-[#003d82] focus:outline-none"
            >
              {isOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile Navigation */}
      {isOpen && (
        <div className="md:hidden">
          <div className="px-2 pt-2 pb-3 space-y-2 bg-[#0f172a]">
            {/* Services links directly in mobile menu */}
            <div className="text-sm font-medium text-gray-400 px-3">Services:</div>
            {serviceLinks.map((link) => (
              <Link
                key={link.href}
                href={link.href}
                className="block px-3 py-2 text-base hover:bg-[#1a222e] rounded-md"
                onClick={handleMobileNavClick}
              >
                {link.label}
              </Link>
            ))}
            
            <Link
              href="/contact"
              className="block px-3 py-2 bg-pink-500 hover:bg-pink-600 text-center rounded-full mt-4"
              onClick={handleMobileNavClick}
            >
              Book Now
            </Link>
          </div>
        </div>
      )}
    </nav>
  );
}
