import { ArrowRight, Building2, Users, CheckCircle2, HelpCircle } from 'lucide-react';
import { Link } from '../components/Link';
import { SEO } from '../components/SEO';

export default function CommercialInstallationPage() {
  return (
    <div className="pt-20 bg-[#1f2937]">
      <SEO 
        title="Commercial DSTV Installation Sandton | Business & Hotel Solutions"
        description="Professional commercial DSTV installation for hotels, restaurants & offices in Sandton. Multi-room setups, HD support & staff training included. Get a quote today!"
        canonicalUrl="/commercial/"
      />
      {/* Header Section */}
      <div className="bg-[#1a222e] text-white py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 className="text-4xl font-bold mb-6">Commercial DSTV Installation</h1>
          <p className="text-xl text-gray-300">
            Professional DSTV solutions for hotels, restaurants, offices, and other 
            commercial establishments. Our expert <Link href="/" className="text-pink-400 hover:text-pink-300">DSTV installers in Sandton</Link> deliver excellence in every installation, 
            ensuring your business gets the entertainment setup it deserves.
          </p>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        {/* Hero Image Section */}
        <div className="grid md:grid-cols-2 gap-12 items-center mb-16">
          <div className="relative">
            <img
              src="/images/Home Theatre.jpg"
              alt="Commercial DSTV Installation"
              className="rounded-2xl shadow-2xl w-full h-[400px] object-cover"
            />
            <div className="absolute inset-0 rounded-2xl bg-gradient-to-r from-pink-500/20 to-transparent"></div>
          </div>
          <div>
            <h2 className="text-2xl font-bold text-white mb-4">Complete Commercial Solutions</h2>
            <p className="text-gray-300">
              As a trusted provider of DSTV installation services, 
              we specialize in large-scale installations for businesses. Our expert team handles everything from 
              single-office setups to multi-floor hotel systems.
            </p>
          </div>
        </div>

        {/* Features Section */}
        <div className="mb-16">
          <div className="text-center mb-16">
            <h2 className="text-3xl font-bold text-white mb-4">Our Commercial Services</h2>
            <p className="text-gray-300 text-lg">
              Comprehensive DSTV solutions for your business needs
            </p>
          </div>

          <div className="grid md:grid-cols-3 gap-8">
            {[
              {
                icon: <Building2 className="h-8 w-8" />,
                title: "Multi-Room Systems",
                description: "Customized solutions for hotels, offices, and large commercial spaces"
              },
              {
                icon: <Users className="h-8 w-8" />,
                title: "Staff Training",
                description: "Comprehensive training for your staff on system operation and management"
              },
              {
                icon: <CheckCircle2 className="h-8 w-8" />,
                title: "Maintenance Support",
                description: "Regular maintenance and priority support for business customers"
              }
            ].map((feature) => (
              <div key={feature.title} className="bg-[#2d3748] p-8 rounded-2xl shadow-lg">
                <div className="text-pink-500 mb-4">{feature.icon}</div>
                <h3 className="text-xl font-bold text-white mb-4">{feature.title}</h3>
                <p className="text-gray-300">{feature.description}</p>
              </div>
            ))}
          </div>
        </div>

        {/* Installation Process */}
        <div className="bg-[#2d3748] rounded-2xl p-8 shadow-xl mb-16">
          <h2 className="text-3xl font-bold text-white mb-8">Implementation Process</h2>
          <div className="grid md:grid-cols-2 gap-8">
            <div className="space-y-6">
              {[
                {
                  step: "1",
                  title: "Consultation",
                  description: "Understanding your business needs and designing the optimal solution"
                },
                {
                  step: "2",
                  title: "Infrastructure Planning",
                  description: "Detailed planning of cable routes, equipment placement, and network design"
                },
                {
                  step: "3",
                  title: "Professional Installation",
                  description: "Expert installation with minimal disruption to your business operations"
                },
                {
                  step: "4",
                  title: "Staff Training",
                  description: "Comprehensive training for your staff on system operation and management"
                }
              ].map((step) => (
                <div key={step.title} className="flex items-start">
                  <div className="bg-pink-500 text-white w-8 h-8 rounded-full flex items-center justify-center flex-shrink-0 mt-1 mr-4">
                    {step.step}
                  </div>
                  <div>
                    <h3 className="text-xl font-semibold text-white mb-2">{step.title}</h3>
                    <p className="text-gray-300">{step.description}</p>
                  </div>
                </div>
              ))}
            </div>
            <div className="bg-[#1f2937] p-8 rounded-xl">
              <h3 className="text-2xl font-bold text-white mb-6">Business Benefits</h3>
              <ul className="space-y-4">
                {[
                  "Customized solutions for your business",
                  <>Advanced <Link href="/extra-view" className="text-pink-400 hover:text-pink-300">multi-room viewing</Link> capabilities</>,
                  "HD and Ultra HD support",
                  "Scalable systems for future expansion",
                  <>Comprehensive <Link href="/maintenance" className="text-pink-400 hover:text-pink-300">maintenance support</Link> plans</>,
                  <>24/7 <Link href="/troubleshooting" className="text-pink-400 hover:text-pink-300">technical assistance</Link></>,
                  "Staff training included",
                  "Extended warranty options"
                ].map((item) => (
                  <li key={typeof item === 'string' ? item : item.props.children[1].props.href} className="flex items-center text-gray-300">
                    <CheckCircle2 className="h-5 w-5 text-pink-500 mr-3 flex-shrink-0" />
                    <span>{item}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        {/* CTA Section */}
        <div className="text-center">
          <h2 className="text-3xl font-bold text-white mb-6">Ready to Upgrade Your Business?</h2>
          <p className="text-xl text-gray-300 mb-8">
            Get professional commercial DSTV installation services today
          </p>
          <Link
            href="/contact"
            className="inline-flex items-center bg-pink-500 text-white px-8 py-4 rounded-full hover:bg-pink-600 transition-all duration-200 shadow-lg hover:shadow-pink-500/25"
          >
            Request Commercial Quote
            <ArrowRight className="ml-2 h-5 w-5" />
          </Link>
        </div>
      </div>
    </div>
  );
}
