import { Hero } from '../components/Hero';
import { Services } from '../components/Services';
import { CallToAction } from '../components/CallToAction';
import { SEO } from '../components/SEO';

export default function HomePage() {
  return (
    <>
      <SEO 
        title="DSTV Installation Sandton | Professional DSTV Installers"
        description="Expert DSTV installation services in Sandton. 15+ years experience, certified technicians, and 24/7 support. Same-day installation available."
        canonicalUrl="/"
      />
      <main>
        <Hero />
        <Services />
        <CallToAction />
      </main>
    </>
  );
}