import React, { useState, useEffect, FormEvent } from 'react';
import { ArrowRight, Mail, Phone, MapPin, CheckCircle2 } from 'lucide-react';
import emailjs from '@emailjs/browser';
import toast, { Toaster } from 'react-hot-toast';
import { Link } from '../components/Link';
import { SEO } from '../components/SEO';
import { SchemaMarkup } from '../components/SchemaMarkup';

export default function ContactPage() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    suburb: '',
    service: '',
    message: ''
  });

  useEffect(() => {
    const publicKey = import.meta.env.VITE_EMAILJS_PUBLIC_KEY;
    if (publicKey) {
      emailjs.init(publicKey);
    }
  }, []);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const serviceId = import.meta.env.VITE_EMAILJS_SERVICE_ID;
      const templateId = import.meta.env.VITE_EMAILJS_TEMPLATE_ID;
      const publicKey = import.meta.env.VITE_EMAILJS_PUBLIC_KEY;

      if (!serviceId || !templateId || !publicKey) {
        throw new Error('EmailJS configuration is missing');
      }

      await emailjs.send(
        serviceId,
        templateId,
        {
          from_name: formData.name,
          from_email: formData.email,
          phone: formData.phone,
          suburb: formData.suburb,
          service: formData.service,
          message: formData.message,
          to_email: 'info@dstvinstallationsandton.co.za'
        },
        publicKey
      );

      toast.success('Message sent successfully! We will contact you shortly.');
      setFormData({
        name: '',
        email: '',
        phone: '',
        suburb: '',
        service: '',
        message: ''
      });
    } catch (error) {
      console.error('Error sending email:', error);
      toast.error('Failed to send message. Please try again or contact us directly.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <SEO 
        title="Contact DSTV Pro Installations Sandton | Book Installation"
        description="Get in touch with Sandton's leading DSTV installers. 24/7 support, same-day service & free quotes. Professional installation & maintenance services."
        canonicalUrl="/contact/"
      />
      <SchemaMarkup type="ContactPage" page="contact" />
      <div className="pt-20 bg-[#1f2937]">
        <Toaster position="top-right" />
        {/* Header Section */}
        <div className="bg-[#1a222e] text-white py-16">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <h1 className="text-4xl font-bold mb-6">Contact Us</h1>
            <p className="text-xl text-gray-300">
              Get in touch with our <Link href="/" className="text-pink-400 hover:text-pink-300">DSTV installers in Sandton</Link> for 
              professional installation and repair services.
            </p>
          </div>
        </div>

        {/* Hero Section with Image */}
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
          <div className="flex flex-col md:flex-row gap-12 items-center mb-16">
            <div className="relative w-full md:w-1/3">
              <img
                src="/images/DSTV-Installation-1.png"
                alt="DSTV Installation Services"
                className="rounded-2xl shadow-2xl w-full h-auto object-cover"
              />
              <div className="absolute inset-0 rounded-2xl bg-gradient-to-r from-pink-500/20 to-transparent"></div>
            </div>
            <div className="md:w-2/3">
              <h2 className="text-2xl font-bold text-white mb-4">Professional DSTV Services</h2>
              <p className="text-gray-300 text-lg mb-6">
                Our team of certified technicians specializes in <Link href="/extra-view" className="text-pink-400 hover:text-pink-300">Extra View installations</Link>, 
                <Link href="/maintenance" className="text-pink-400 hover:text-pink-300"> system maintenance</Link>, and 
                <Link href="/commercial" className="text-pink-400 hover:text-pink-300"> commercial DSTV solutions</Link>. If you're experiencing signal issues, 
                our <Link href="/troubleshooting" className="text-pink-400 hover:text-pink-300">expert troubleshooting</Link> team is ready to help restore your service. 
                We provide comprehensive solutions for residential and commercial clients throughout Sandton and surrounding areas.
              </p>
              <div className="flex flex-wrap gap-4">
                <div className="flex items-center text-gray-300">
                  <CheckCircle2 className="h-5 w-5 text-pink-500 mr-2" />
                  <span>Expert Installation</span>
                </div>
                <div className="flex items-center text-gray-300">
                  <CheckCircle2 className="h-5 w-5 text-pink-500 mr-2" />
                  <span>24/7 Support</span>
                </div>
                <div className="flex items-center text-gray-300">
                  <CheckCircle2 className="h-5 w-5 text-pink-500 mr-2" />
                  <span>Quality Service</span>
                </div>
              </div>
            </div>
          </div>

          {/* Contact Form and Info Grid */}
          <div className="grid md:grid-cols-2 gap-12">
            {/* Form Section */}
            <div className="bg-[#2d3748] p-8 rounded-2xl shadow-xl">
              <h2 className="text-2xl font-bold text-white mb-6">
                Request Service
              </h2>
              <form onSubmit={handleSubmit} className="space-y-6">
                <div>
                  <label htmlFor="name" className="block text-sm font-medium text-gray-300">
                    Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    required
                    value={formData.name}
                    onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                    className="mt-1 block w-full rounded-md border-gray-600 bg-gray-700 text-white shadow-sm focus:border-pink-500 focus:ring-pink-500"
                  />
                </div>
                <div>
                  <label htmlFor="email" className="block text-sm font-medium text-gray-300">
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    required
                    value={formData.email}
                    onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                    className="mt-1 block w-full rounded-md border-gray-600 bg-gray-700 text-white shadow-sm focus:border-pink-500 focus:ring-pink-500"
                  />
                </div>
                <div>
                  <label htmlFor="phone" className="block text-sm font-medium text-gray-300">
                    Phone
                  </label>
                  <input
                    type="tel"
                    id="phone"
                    name="phone"
                    required
                    value={formData.phone}
                    onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
                    className="mt-1 block w-full rounded-md border-gray-600 bg-gray-700 text-white shadow-sm focus:border-pink-500 focus:ring-pink-500"
                  />
                </div>
                <div>
                  <label htmlFor="suburb" className="block text-sm font-medium text-gray-300">
                    Suburb
                  </label>
                  <input
                    type="text"
                    id="suburb"
                    name="suburb"
                    required
                    value={formData.suburb}
                    onChange={(e) => setFormData({ ...formData, suburb: e.target.value })}
                    className="mt-1 block w-full rounded-md border-gray-600 bg-gray-700 text-white shadow-sm focus:border-pink-500 focus:ring-pink-500"
                  />
                </div>
                <div>
                  <label htmlFor="service" className="block text-sm font-medium text-gray-300">
                    Service Required
                  </label>
                  <select
                    id="service"
                    name="service"
                    required
                    value={formData.service}
                    onChange={(e) => setFormData({ ...formData, service: e.target.value })}
                    className="mt-1 block w-full rounded-md border-gray-600 bg-gray-700 text-white shadow-sm focus:border-pink-500 focus:ring-pink-500"
                  >
                    <option value="">Select a service</option>
                    <option value="installation">New Installation</option>
                    <option value="extra-view">Extra View Setup</option>
                    <option value="troubleshooting">Signal Problems</option>
                    <option value="maintenance">Maintenance</option>
                    <option value="commercial">Commercial Installation</option>
                  </select>
                </div>
                <div>
                  <label htmlFor="message" className="block text-sm font-medium text-gray-300">
                    Message
                  </label>
                  <textarea
                    id="message"
                    name="message"
                    rows={4}
                    required
                    value={formData.message}
                    onChange={(e) => setFormData({ ...formData, message: e.target.value })}
                    className="mt-1 block w-full rounded-md border-gray-600 bg-gray-700 text-white shadow-sm focus:border-pink-500 focus:ring-pink-500"
                  />
                </div>
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="w-full bg-pink-500 text-white px-6 py-3 rounded-md hover:bg-pink-600 disabled:opacity-50 transition-all duration-200 shadow-lg hover:shadow-pink-500/25"
                >
                  {isSubmitting ? 'Sending...' : 'Send Message'}
                </button>
              </form>
            </div>

            {/* Contact Information */}
            <div className="space-y-8">
              <div className="bg-[#2d3748] p-8 rounded-2xl shadow-xl">
                <h2 className="text-2xl font-bold text-white mb-6">
                  Contact Information
                </h2>
                <div className="space-y-6">
                  <div className="flex items-start">
                    <Phone className="h-6 w-6 text-pink-500 mr-3 flex-shrink-0 mt-1" />
                    <div>
                      <h3 className="font-semibold text-white mb-1">Phone</h3>
                      <a href="tel:+27787294594" className="text-gray-300 hover:text-pink-400">
                        +27 78 729 4594
                      </a>
                    </div>
                  </div>
                  <div className="flex items-start">
                    <Mail className="h-6 w-6 text-pink-500 mr-3 flex-shrink-0 mt-1" />
                    <div>
                      <h3 className="font-semibold text-white mb-1">Email</h3>
                      <a 
                        href="mailto:info@dstvinstallationsandton.co.za" 
                        className="text-gray-300 hover:text-pink-400 break-all"
                      >
                        info@dstvinstallationsandton.co.za
                      </a>
                    </div>
                  </div>
                  <div className="flex items-start">
                    <MapPin className="h-6 w-6 text-pink-500 mr-3 flex-shrink-0 mt-1" />
                    <div>
                      <h3 className="font-semibold text-white mb-1">Service Areas</h3>
                      <p className="text-gray-300">
                        Sandton, Randburg, Midrand, Johannesburg North, Roodepoort, and surrounding areas
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="bg-[#2d3748] p-8 rounded-2xl shadow-xl">
                <h3 className="text-2xl font-bold text-white mb-4">
                  Business Hours
                </h3>
                <div className="space-y-2 text-gray-300">
                  <p>Monday - Friday: 8:00 AM - 6:00 PM</p>
                  <p>Saturday: 9:00 AM - 4:00 PM</p>
                  <p>Sunday: By appointment only</p>
                  <p className="mt-4 font-semibold text-pink-400">24/7 Emergency Support Available</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
