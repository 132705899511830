import { ArrowRight, Wrench, ShieldCheck, Clock, CheckCircle2, AlertTriangle, Zap } from 'lucide-react';
import { Link } from '../components/Link';
import { SEO } from '../components/SEO';

export default function MaintenancePage() {
  return (
    <div className="pt-20 bg-[#1f2937]">
      <SEO 
        title="DSTV Maintenance Services Sandton | Professional DSTV Repairs"
        description="Expert DSTV maintenance and repair services in Sandton. 24/7 support, signal optimization, and hardware checks by certified technicians. Prevent signal issues today."
        canonicalUrl="/maintenance/"
      />
      {/* Header Section */}
      <div className="bg-[#1a222e] text-white py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 className="text-4xl font-bold mb-6">DSTV Maintenance Services</h1>
          <p className="text-xl text-gray-300">
            Keep your DSTV system running smoothly with our professional maintenance services. 
            Regular maintenance prevents signal issues and extends the life of your equipment.
          </p>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        {/* Hero Image Section */}
        <div className="grid md:grid-cols-2 gap-12 items-center mb-16">
          <div className="relative">
            <img
              src="/images/dstv-maintenance.jpg"
              alt="DSTV Maintenance Service"
              className="rounded-2xl shadow-2xl w-full h-auto object-cover"
            />
            <div className="absolute inset-0 rounded-2xl bg-gradient-to-r from-pink-500/20 to-transparent"></div>
          </div>
          <div>
            <h2 className="text-2xl font-bold text-white mb-4">Professional DSTV Maintenance</h2>
            <p className="text-gray-300">
              Our experienced <Link href="/" className="text-pink-400 hover:text-pink-300">Sandton DSTV installers</Link> provide 
              comprehensive maintenance services to ensure optimal performance of your DSTV system. From 
              <Link href="/commercial" className="text-pink-400 hover:text-pink-300"> large-scale commercial installations</Link> to 
              residential setups, our team's expertise in preventive maintenance helps avoid common issues like signal loss, 
              pixelation, and error codes.
            </p>
          </div>
        </div>

        {/* Services Grid */}
        <div className="mb-16">
          <h2 className="text-3xl font-bold text-white text-center mb-12">Our Maintenance Services</h2>
          <div className="grid md:grid-cols-3 gap-8">
            {[
              {
                icon: <ShieldCheck className="h-8 w-8" />,
                title: "Preventive Maintenance",
                description: "Regular system checks and updates to prevent future issues"
              },
              {
                icon: <Wrench className="h-8 w-8" />,
                title: "Equipment Repairs",
                description: "Expert repairs for decoders, dishes, and LNBs"
              },
              {
                icon: <Zap className="h-8 w-8" />,
                title: "Signal Optimization",
                description: "Professional signal strength testing and adjustment"
              },
              {
                icon: <AlertTriangle className="h-8 w-8" />,
                title: "Problem Diagnosis",
                description: "Accurate identification of system issues and solutions"
              },
              {
                icon: <Clock className="h-8 w-8" />,
                title: "Regular Check-ups",
                description: "Scheduled maintenance visits to ensure system health"
              },
              {
                icon: <CheckCircle2 className="h-8 w-8" />,
                title: "Quality Assurance",
                description: "Comprehensive testing after every service"
              }
            ].map((service) => (
              <div key={service.title} className="bg-[#2d3748] p-6 rounded-xl shadow-lg">
                <div className="text-pink-500 mb-4">{service.icon}</div>
                <h3 className="text-xl font-bold text-white mb-2">{service.title}</h3>
                <p className="text-gray-300">{service.description}</p>
              </div>
            ))}
          </div>
        </div>

        {/* Benefits Section */}
        <div className="mb-16">
          <h2 className="text-3xl font-bold text-white mb-8">Why Regular Maintenance Matters</h2>
          <div className="bg-[#2d3748] rounded-2xl p-8">
            <ul className="space-y-4">
              {[
                <>Visit our <Link href="/troubleshooting" className="text-pink-400 hover:text-pink-300">troubleshooting guide</Link> for help with signal loss and picture quality issues</>,
                "Extends the lifespan of your DSTV equipment",
                "Ensures optimal performance during bad weather",
                "Reduces the risk of major system failures",
                "Saves money on costly emergency repairs",
                "Maintains warranty validity"
              ].map((benefit) => (
                <li key={typeof benefit === 'string' ? benefit : 'troubleshooting'} className="flex items-center text-gray-300">
                  <CheckCircle2 className="h-5 w-5 text-pink-500 mr-3 flex-shrink-0" />
                  <span>{benefit}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>

        {/* CTA Section */}
        <div className="text-center">
          <h2 className="text-3xl font-bold text-white mb-6">Ready to Schedule Maintenance?</h2>
          <p className="text-xl text-gray-300 mb-8">
            Keep your DSTV system in perfect condition with our professional maintenance service
          </p>
          <Link
            href="/contact"
            className="inline-flex items-center bg-pink-500 text-white px-8 py-4 rounded-full hover:bg-pink-600 transition-all duration-200 shadow-lg hover:shadow-pink-500/25"
          >
            Book Maintenance Service
            <ArrowRight className="ml-2 h-5 w-5" />
          </Link>
        </div>
      </div>
    </div>
  );
}
