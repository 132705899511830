import { ArrowRight, Signal, AlertTriangle, CheckCircle2, HelpCircle } from 'lucide-react';
import { Link } from '../components/Link';
import { Helmet } from 'react-helmet-async';
import { SEO } from '../components/SEO';

export default function TroubleshootingPage() {
  const faqSchema = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [
      {
        "@type": "Question",
        "name": "What should I do if my DSTV has no signal?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "First check all cable connections, ensure your dish isn't obstructed, and restart your decoder. If issues persist, contact our professional technicians."
        }
      },
      {
        "@type": "Question",
        "name": "How often should I service my DSTV system?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "We recommend a professional service check every 12-18 months to ensure optimal performance and prevent signal issues."
        }
      }
      // Add more FAQ items
    ]
  };

  return (
    <>
      <SEO 
        title="DSTV Troubleshooting Guide Sandton | Expert Solutions"
        description="Professional DSTV troubleshooting services in Sandton. Quick fixes for signal issues, decoder problems & picture quality. 24/7 emergency support."
        canonicalUrl="/troubleshooting/"
      />
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(faqSchema)}
        </script>
      </Helmet>
      <div className="pt-20 bg-[#1f2937]">
        {/* Header Section */}
        <div className="bg-[#1a222e] text-white py-16">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <h1 className="text-4xl font-bold mb-6">DSTV Troubleshooting Guide</h1>
            <p className="text-xl text-gray-300">
              Expert solutions for common DSTV problems and quick fixes for better viewing. Our team of <Link href="/" className="text-pink-400 hover:text-pink-300">DSTV installation in Sandton</Link> specialists 
              can diagnose and resolve a wide range of issues, from E48-32 error codes to signal strength problems. Whether you're experiencing decoder freezing, pixelation issues, or 
              error message E16-4, our technicians are equipped with advanced diagnostic tools to restore your entertainment system to optimal performance.
            </p>
          </div>
        </div>

        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
          <div className="grid md:grid-cols-2 gap-12 mb-16">
            {/* Quick Fixes Section */}
            <div>
              <h2 className="text-3xl font-bold text-white mb-6">
                Quick Fixes
              </h2>
              <p className="text-gray-300 mb-6">
                Try these troubleshooting steps before calling for professional help. If problems persist,{' '}
                <Link href="/contact" className="text-pink-500 hover:text-pink-400">
                  contact our experts
                </Link>.
              </p>
              <div className="bg-[#2d3748] p-8 rounded-2xl shadow-xl">
                <h3 className="text-xl font-semibold text-white mb-4">
                  Basic Steps
                </h3>
                <ul className="space-y-4">
                  {[
                    "Check all cable connections",
                    "Restart your decoder",
                    "Verify dish alignment",
                    "Clear any obstructions",
                    "Check for weather interference"
                  ].map((step) => (
                    <li key={step} className="flex items-start">
                      <CheckCircle2 className="h-5 w-5 text-pink-500 mr-3 flex-shrink-0 mt-1" />
                      <span className="text-gray-300">{step}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            {/* Common Issues Section */}
            <div className="bg-[#2d3748] rounded-2xl shadow-xl p-8">
              <h2 className="text-3xl font-bold text-white mb-6">
                Common Issues
              </h2>
              <div className="space-y-6">
                {[
                  {
                    title: "Signal Loss",
                    description: "Check cables and dish alignment for proper signal reception."
                  },
                  {
                    title: "Error Codes",
                    description: "Most error codes can be resolved with a simple decoder restart."
                  },
                  {
                    title: "Picture Quality",
                    description: "Poor picture quality often indicates weak signal strength."
                  },
                  {
                    title: "Weather Impact",
                    description: "Severe weather can temporarily affect your signal quality."
                  }
                ].map((issue) => (
                  <div key={issue.title} className="flex items-start">
                    <AlertTriangle className="h-6 w-6 text-pink-500 mr-3 flex-shrink-0 mt-1" />
                    <div>
                      <h3 className="font-semibold text-white mb-2">{issue.title}</h3>
                      <p className="text-gray-300">{issue.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* FAQ Section */}
          <div className="bg-[#2d3748] rounded-2xl p-8 mb-16 shadow-xl">
            <h2 className="text-3xl font-bold text-white mb-6">
              Troubleshooting FAQs
            </h2>
            <div className="grid md:grid-cols-2 gap-6">
              {[
                {
                  q: "Why is my screen blank?",
                  a: "A blank screen usually indicates no signal reception. Check your connections and dish alignment."
                },
                {
                  q: "What does E48-32 mean?",
                  a: "This error typically indicates a signal problem. Try restarting your decoder and checking connections."
                },
                {
                  q: "How do I check signal strength?",
                  a: "Access your decoder menu, navigate to settings, and look for the signal strength indicator."
                },
                {
                  q: "When should I schedule maintenance?",
                  a: <>We recommend regular <Link href="/maintenance" className="text-pink-400 hover:text-pink-300">professional maintenance</Link> every 12-18 months to prevent signal issues and ensure optimal performance.</>
                }
              ].map((faq) => (
                <div key={typeof faq.q === 'string' ? faq.q : 'maintenance'} className="flex items-start">
                  <HelpCircle className="h-6 w-6 text-pink-500 mr-3 flex-shrink-0 mt-1" />
                  <div>
                    <h3 className="font-semibold text-white mb-2">{faq.q}</h3>
                    <p className="text-gray-300">{faq.a}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* CTA Section */}
          <div className="text-center">
            <p className="text-xl text-gray-300 mb-8">
              Need professional assistance?{' '}
              <Link href="/contact" className="text-pink-500 hover:text-pink-400">
                Contact our team
              </Link>{' '}
              for expert troubleshooting help.
            </p>
            <Link
              href="/contact"
              className="inline-flex items-center bg-pink-500 text-white px-8 py-4 rounded-full hover:bg-pink-600 transition-all duration-200 shadow-lg hover:shadow-pink-500/25"
            >
              Get Expert Help
              <ArrowRight className="ml-2 h-5 w-5" />
            </Link>
          </div>
        </div>

        {/* Background Decorative Elements */}
        <div className="absolute inset-0 overflow-hidden pointer-events-none">
          <div className="absolute -top-1/2 -right-1/2 w-[1000px] h-[1000px] bg-gradient-to-br from-pink-500/20 to-transparent rounded-full blur-3xl"></div>
          <div className="absolute -bottom-1/2 -left-1/2 w-[1000px] h-[1000px] bg-gradient-to-tr from-pink-500/10 to-transparent rounded-full blur-3xl"></div>
        </div>
      </div>
    </>
  );
}
