import { ArrowRight, CheckCircle2 } from 'lucide-react';
import { Link } from './Link';

export function CallToAction() {
  return (
    <div className="bg-[#1a222e] py-20">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="bg-gradient-to-br from-gray-800 to-gray-900 rounded-3xl p-8 md:p-12 shadow-2xl border border-gray-700/50">
          <div className="grid md:grid-cols-2 gap-12 items-center">
            <div className="space-y-6">
              <h2 className="text-3xl font-bold text-white">
                Expert DSTV Installation in Sandton
              </h2>
              <div className="space-y-4">
                <p className="text-gray-300 leading-relaxed">
                  Looking for reliable DSTV installation in Sandton? We've got you covered! Our expert technicians are here to help with all your satellite TV needs, whether it's for your home or business.
                </p>
                <p className="text-gray-300 leading-relaxed">
                  Don't let poor reception or technical issues keep you from enjoying your favorite shows. Our skilled team has years of experience and will get your system working perfectly at prices that won't break the bank.
                </p>
              </div>
              <Link
                href="/contact"
                className="inline-flex items-center px-6 py-3 text-lg font-semibold rounded-full text-white bg-pink-600 hover:bg-pink-700 transition-all duration-300"
              >
                Schedule Installation
                <ArrowRight className="ml-2 h-5 w-5" />
              </Link>
            </div>
            
            <div className="bg-gray-800/50 rounded-xl p-6 space-y-6">
              <h3 className="text-xl font-semibold text-white">Why Choose Us?</h3>
              <ul className="space-y-4">
                {[
                  "Certified DSTV Technicians",
                  "Quick Response Time",
                  "Competitive Pricing",
                  "Quality Workmanship",
                  "All Sandton Areas Covered",
                  "Crystal-Clear Reception Guaranteed"
                ].map((item) => (
                  <li key={item} className="flex items-center text-gray-300">
                    <CheckCircle2 className="h-5 w-5 text-pink-500 mr-3 flex-shrink-0" />
                    <span>{item}</span>
                  </li>
                ))}
              </ul>
              <div className="pt-4 border-t border-gray-700">
                <p className="text-gray-300">
                  Need help choosing the right package? Having signal problems? Want to upgrade your existing setup? We'll send a certified technician to sort it out quickly and efficiently.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
} 