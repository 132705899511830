import { ArrowRight, Shield, Smartphone, Tool, Eye, Video, Wifi, Server } from 'lucide-react';
import { Link } from '../components/Link';
import { SEO } from '../components/SEO';

export default function CCTVPage() {
  return (
    <>
      <SEO 
        title="CCTV Installation Sandton | Professional Security Camera Setup"
        description="Expert CCTV camera installation in Sandton. HD surveillance systems, remote viewing & 24/7 monitoring. Protect your property with our professional security solutions."
        canonicalUrl="/cctv/"
      />
      <div className="pt-20 bg-[#1f2937]">
        {/* Header Section */}
        <div className="bg-[#1a222e] text-white py-16">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <h1 className="text-4xl font-bold mb-6">CCTV Camera Installation</h1>
            <p className="text-xl text-gray-300">
              Enhance your property's security with our professional CCTV installation services. 
              From indoor surveillance to perimeter protection, we provide comprehensive security 
              solutions with remote viewing capabilities.
            </p>
          </div>
        </div>

        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
          {/* Hero Image Section */}
          <div className="grid md:grid-cols-2 gap-12 items-center mb-16">
            <div className="relative">
              <img
                src="/images/cctv installation.jpg"
                alt="CCTV Camera Installation"
                className="rounded-2xl shadow-2xl w-full h-auto object-cover"
              />
              <div className="absolute inset-0 rounded-2xl bg-gradient-to-r from-pink-500/20 to-transparent"></div>
            </div>
            <div>
              <h2 className="text-2xl font-bold text-white mb-4">Complete Security Solutions</h2>
              <p className="text-gray-300">
                As a trusted provider of <Link href="/" className="text-pink-400 hover:text-pink-300">DSTV installation</Link> and 
                security services, we deliver professional CCTV solutions that protect your property 24/7. 
                Our systems offer crystal-clear footage and convenient remote access from any device.
              </p>
            </div>
          </div>

          {/* Features Section */}
          <div className="mb-16">
            <div className="text-center mb-16">
              <h2 className="text-3xl font-bold text-white mb-4">Our CCTV Services</h2>
              <p className="text-gray-300 text-lg">
                Comprehensive security solutions for your peace of mind
              </p>
            </div>

            <div className="grid md:grid-cols-3 gap-8">
              {[
                {
                  icon: <Video className="h-8 w-8" />,
                  title: "Indoor Cameras",
                  description: "High-definition cameras for monitoring interior spaces with night vision capability"
                },
                {
                  icon: <Shield className="h-8 w-8" />,
                  title: "Perimeter Security",
                  description: "Weather-resistant outdoor cameras for complete perimeter coverage"
                },
                {
                  icon: <Smartphone className="h-8 w-8" />,
                  title: "Remote Viewing",
                  description: "Access live footage from anywhere using your smartphone, tablet, or computer"
                }
              ].map((feature) => (
                <div key={feature.title} className="bg-[#2d3748] p-8 rounded-2xl shadow-lg">
                  <div className="text-pink-500 mb-4">{feature.icon}</div>
                  <h3 className="text-xl font-bold text-white mb-4">{feature.title}</h3>
                  <p className="text-gray-300">{feature.description}</p>
                </div>
              ))}
            </div>
          </div>

          {/* Installation Process */}
          <div className="bg-[#2d3748] rounded-2xl p-8 shadow-xl mb-16">
            <h2 className="text-3xl font-bold text-white mb-8">Professional Installation Process</h2>
            <div className="grid md:grid-cols-2 gap-8">
              <div className="space-y-6">
                {[
                  {
                    step: "1",
                    title: "Site Assessment",
                    description: "Comprehensive evaluation of your property to determine optimal camera placement"
                  },
                  {
                    step: "2",
                    title: "System Design",
                    description: "Custom security solution design based on your specific requirements"
                  },
                  {
                    step: "3",
                    title: "Professional Installation",
                    description: "Expert installation of cameras, DVR/NVR, and all necessary wiring"
                  },
                  {
                    step: "4",
                    title: "Remote Setup",
                    description: "Configuration of remote viewing access and user training"
                  }
                ].map((step) => (
                  <div key={step.title} className="flex items-start">
                    <div className="bg-pink-500 text-white w-8 h-8 rounded-full flex items-center justify-center flex-shrink-0 mt-1 mr-4">
                      {step.step}
                    </div>
                    <div>
                      <h3 className="text-xl font-semibold text-white mb-2">{step.title}</h3>
                      <p className="text-gray-300">{step.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div className="bg-[#1f2937] p-8 rounded-xl">
                <h3 className="text-2xl font-bold text-white mb-6">System Features</h3>
                <ul className="space-y-4">
                  {[
                    "HD/4K resolution cameras",
                    "Night vision capability",
                    "Motion detection alerts",
                    "Mobile app access",
                    "Cloud storage options",
                    "24/7 continuous recording",
                    "Easy footage export",
                    "Weather-resistant equipment"
                  ].map((item) => (
                    <li key={item} className="flex items-center text-gray-300">
                      <Eye className="h-5 w-5 text-pink-500 mr-3 flex-shrink-0" />
                      <span>{item}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          {/* CTA Section */}
          <div className="text-center">
            <h2 className="text-3xl font-bold text-white mb-6">Ready to Secure Your Property?</h2>
            <p className="text-xl text-gray-300 mb-8">
              Get professional CCTV installation with remote viewing capabilities
            </p>
            <Link
              href="/contact"
              className="inline-flex items-center bg-pink-500 text-white px-8 py-4 rounded-full hover:bg-pink-600 transition-all duration-200 shadow-lg hover:shadow-pink-500/25"
            >
              Book Your Installation
              <ArrowRight className="ml-2 h-5 w-5" />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
} 