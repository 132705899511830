import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useEffect } from 'react';
import { RouteLogger } from './components/RouteLogger';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import HomePage from './pages/HomePage';
import ExtraViewPage from './pages/ExtraViewPage';
import MaintenancePage from './pages/MaintenancePage';
import TVInstallationPage from './pages/TVInstallationPage';
import CCTVPage from './pages/CCTVPage';
import CommercialInstallationPage from './pages/CommercialInstallationPage';
import TroubleshootingPage from './pages/TroubleshootingPage';
import ContactPage from './pages/ContactPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import TermsPage from './pages/TermsPage';

interface LayoutProps {
  children: React.ReactNode;
}

function Layout({ children }: LayoutProps) {
  useEffect(() => {
    console.log('Layout mounted', new Date().toISOString());
  }, []);

  return (
    <div className="min-h-screen bg-[#1f2937] flex flex-col">
      <Navbar />
      <main className="flex-grow">
        {children}
      </main>
      <Footer />
    </div>
  );
}

// HOC for logging
const withLogging = (WrappedComponent: React.ComponentType, pageName: string) => {
  return function WithLoggingComponent(props: any) {
    useEffect(() => {
      console.log(`${pageName} Page Mounted`, new Date().toISOString());
      return () => console.log(`${pageName} Page Unmounted`, new Date().toISOString());
    }, []);

    return <WrappedComponent {...props} />;
  };
};

// Wrap components with logging
const LoggedHomePage = withLogging(HomePage, 'Home');
const LoggedExtraViewPage = withLogging(ExtraViewPage, 'ExtraView');
const LoggedMaintenancePage = withLogging(MaintenancePage, 'Maintenance');
const LoggedTVInstallationPage = withLogging(TVInstallationPage, 'TVInstallation');
const LoggedCCTVPage = withLogging(CCTVPage, 'CCTV');
const LoggedCommercialPage = withLogging(CommercialInstallationPage, 'Commercial');
const LoggedTroubleshootingPage = withLogging(TroubleshootingPage, 'Troubleshooting');
const LoggedContactPage = withLogging(ContactPage, 'Contact');
const LoggedPrivacyPolicyPage = withLogging(PrivacyPolicyPage, 'PrivacyPolicy');
const LoggedTermsPage = withLogging(TermsPage, 'Terms');

function App() {
  useEffect(() => {
    console.log('App Component Mounted', new Date().toISOString());
  }, []);

  return (
    <HelmetProvider>
      <BrowserRouter>
        <RouteLogger />
        <Layout>
          <Routes>
            <Route path="/" element={<LoggedHomePage />} />
            <Route path="/extra-view" element={<LoggedExtraViewPage />} />
            <Route path="/maintenance" element={<LoggedMaintenancePage />} />
            <Route path="/tv-installation" element={<LoggedTVInstallationPage />} />
            <Route path="/cctv" element={<LoggedCCTVPage />} />
            <Route path="/commercial" element={<LoggedCommercialPage />} />
            <Route path="/troubleshooting" element={<LoggedTroubleshootingPage />} />
            <Route path="/contact" element={<LoggedContactPage />} />
            <Route path="/privacy-policy" element={<LoggedPrivacyPolicyPage />} />
            <Route path="/terms" element={<LoggedTermsPage />} />
            <Route path="*" element={<LoggedHomePage />} />
          </Routes>
        </Layout>
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;
