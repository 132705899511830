import { ArrowRight, Tv, Monitor, Settings, Building2, Wrench, Camera } from 'lucide-react';
import { Link } from './Link';

const services = [
  {
    image: "/images/DSTV-Installation-1.png",
    icon: <Tv className="h-6 w-6" />,
    title: "DSTV Installation",
    description: "Professional DSTV installation with guaranteed signal quality",
    href: "/services#dstv-installation"
  },
  {
    image: "/images/extra-review-installation-1.jpg",
    icon: <Monitor className="h-6 w-6" />,
    title: "Extra View Setup",
    description: "Connect multiple TVs to one subscription for whole-home entertainment",
    href: "/extra-view"
  },
  {
    image: "/images/DSTV Maintenance.jpg",
    icon: <Wrench className="h-6 w-6" />,
    title: "Maintenance",
    description: "Regular maintenance and support services to keep your system optimal",
    href: "/maintenance"
  },
  {
    image: "/images/TV Mounting.jpg",
    icon: <Settings className="h-6 w-6" />,
    title: "TV Installation",
    description: "Professional TV mounting and home theater setup services",
    href: "/tv-installation"
  },
  {
    image: "/images/cctv installation.jpg",
    icon: <Camera className="h-6 w-6" />,
    title: "CCTV Installation", 
    description: "Comprehensive security solutions with remote viewing capabilities",
    href: "/cctv"
  },
  {
    image: "/images/Home Theatre.jpg",
    icon: <Building2 className="h-6 w-6" />,
    title: "Commercial Installation",
    description: "Specialized DSTV solutions for businesses and commercial properties",
    href: "/commercial"
  }
];

export function Services() {
  return (
    <section className="bg-gradient-to-b from-gray-900 to-gray-800 py-20">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-white mb-4">
            Our Services
          </h2>
          <p className="text-xl text-gray-300">
            Comprehensive DSTV solutions for homes and businesses
          </p>
        </div>

        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
          {services.map((service) => (
            <Link
              href={service.href}
              key={service.title}
              className="group bg-gray-800/50 backdrop-blur rounded-2xl overflow-hidden border border-gray-700/50 hover:border-pink-500/50 transition-all duration-300"
            >
              <div className="relative h-56 overflow-hidden">
                <img
                  src={service.image}
                  alt={service.title}
                  className="w-full h-full object-cover transform group-hover:scale-105 transition-transform duration-500"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-gray-900 via-gray-900/20 to-transparent opacity-60" />
              </div>
              <div className="p-6">
                <div className="inline-flex items-center justify-center w-12 h-12 rounded-xl bg-pink-500/10 text-pink-500 mb-4">
                  {service.icon}
                </div>
                <h3 className="text-xl font-bold text-white mb-2">{service.title}</h3>
                <p className="text-gray-300 mb-4 line-clamp-2">{service.description}</p>
                <div className="flex items-center text-pink-500 group-hover:text-pink-400">
                  Learn more <ArrowRight className="ml-2 h-5 w-5" />
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </section>
  );
}