import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

export function RouteLogger() {
  const location = useLocation();

  useEffect(() => {
    console.log('Current Route:', {
      pathname: location.pathname,
      search: location.search,
      hash: location.hash,
      timestamp: new Date().toISOString()
    });
  }, [location]);

  return null;
} 