import { ArrowRight, Phone } from 'lucide-react';
import { Link } from './Link';

export function Hero() {
  return (
    <div className="relative bg-gradient-to-b from-gray-900 to-gray-800 text-white pt-24 pb-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:grid lg:grid-cols-2 gap-12">
          {/* Left Column */}
          <div className="space-y-6">
            <h1 className="text-5xl font-bold leading-tight">
              Expert DSTV Installation in Sandton
            </h1>
            
            <h2 className="text-2xl font-semibold text-gray-300">
              DSTV Installers in Sandton
            </h2>

            <p className="text-xl text-gray-300 leading-relaxed">
              Looking for reliable DSTV installation in Sandton? We've got you covered! 
              Our expert technicians are here to help with all your satellite TV needs, 
              whether it's for your home or business.
            </p>

            <p className="text-xl text-gray-300 leading-relaxed">
              Don't let poor reception or technical issues keep you from enjoying your favorite shows. 
              Our skilled team has years of experience and will get your system working perfectly at 
              prices that won't break the bank.
            </p>

            <div className="flex flex-col sm:flex-row gap-4 pt-4">
              <Link
                href="/contact"
                className="inline-flex items-center justify-center px-8 py-4 text-lg font-semibold rounded-full text-white bg-pink-600 hover:bg-pink-700 transition-all duration-300 shadow-lg shadow-pink-600/25 hover:shadow-pink-600/40"
              >
                Book Installation
                <ArrowRight className="ml-2 h-5 w-5" />
              </Link>
            </div>
          </div>

          {/* Right Column */}
          <div className="mt-12 lg:mt-0 space-y-6">
            {/* Emergency Support Card */}
            <div className="bg-gray-800/50 backdrop-blur rounded-2xl p-8 shadow-xl border border-gray-700/50 hover:border-pink-500/50 transition-all duration-300">
              <h3 className="text-2xl font-bold mb-4 text-white">
                24/7 Emergency Support
              </h3>
              <p className="text-gray-300 mb-6">
                Experiencing signal issues or technical problems? Our expert team is available
                around the clock for immediate assistance.
              </p>
              <a
                href="tel:+27787294594"
                className="inline-flex items-center text-xl font-semibold text-pink-500 hover:text-pink-400 transition-colors"
              >
                <Phone className="h-6 w-6 mr-3" />
                +27 78 729 4594
              </a>
            </div>

            {/* Professional Equipment Card */}
            <div className="bg-gray-800/50 backdrop-blur rounded-2xl p-8 shadow-xl border border-gray-700/50 hover:border-pink-500/50 transition-all duration-300">
              <h3 className="text-2xl font-bold mb-4 text-white">
                Professional Equipment
              </h3>
              <p className="text-gray-300 mb-6">
                We use only genuine equipment and professional-grade tools to ensure optimal performance.
              </p>
              <ul className="grid grid-cols-2 gap-4 text-gray-300">
                <li className="flex items-center space-x-2">
                  <span className="w-2 h-2 bg-pink-500 rounded-full"></span>
                  <span>Latest Decoders</span>
                </li>
                <li className="flex items-center space-x-2">
                  <span className="w-2 h-2 bg-pink-500 rounded-full"></span>
                  <span>HD & Ultra HD Compatible</span>
                </li>
                <li className="flex items-center space-x-2">
                  <span className="w-2 h-2 bg-pink-500 rounded-full"></span>
                  <span>Professional Tools</span>
                </li>
                <li className="flex items-center space-x-2">
                  <span className="w-2 h-2 bg-pink-500 rounded-full"></span>
                  <span>Signal Testing Equipment</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* Background Decorative Elements */}
      <div className="absolute inset-0 overflow-hidden pointer-events-none">
        <div className="absolute -top-1/2 -right-1/2 w-[1000px] h-[1000px] bg-gradient-to-br from-pink-500/20 to-transparent rounded-full blur-3xl"></div>
        <div className="absolute -bottom-1/2 -left-1/2 w-[1000px] h-[1000px] bg-gradient-to-tr from-pink-500/10 to-transparent rounded-full blur-3xl"></div>
      </div>
    </div>
  );
}
