import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

interface LinkProps {
  href: string;
  children: React.ReactNode;
  className?: string;
  onClick?: () => void;
}

export function Link({ href, children, className, onClick, ...props }: LinkProps) {
  const handleClick = (e: React.MouseEvent) => {
    if (onClick) {
      onClick();
    }
    // Scroll to top after a short delay to ensure navigation has completed
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 100);
  };

  return (
    <RouterLink to={href} className={className} onClick={handleClick} {...props}>
      {children}
    </RouterLink>
  );
}
