import { Helmet } from 'react-helmet-async';

interface SchemaProps {
  type: 'LocalBusiness' | 'Service' | 'WebPage' | 'ContactPage' | 'FAQPage';
  page: string;
}

export function SchemaMarkup({ type, page }: SchemaProps) {
  const baseSchema = {
    "@context": "https://schema.org",
    "@type": type,
    "name": "DSTV Pro Installations Sandton",
    "url": "https://www.dstvinstallationsandton.co.za",
    "logo": "https://www.dstvinstallationsandton.co.za/satellite-dish.svg",
    "address": {
      "@type": "PostalAddress",
      "addressLocality": "Sandton",
      "addressRegion": "Gauteng",
      "addressCountry": "ZA"
    },
    "geo": {
      "@type": "GeoCoordinates",
      "latitude": "-26.1071",
      "longitude": "28.0567"
    },
    "telephone": "+27787294594",
    "priceRange": "$$"
  };

  const schemas = {
    home: {
      ...baseSchema,
      "description": "Professional DSTV installation services in Sandton. Expert technicians, same-day service, and 24/7 support.",
      "sameAs": [
        "https://www.facebook.com/dstvinstallationsandton",
        // Add other social media URLs
      ],
      "openingHoursSpecification": {
        "@type": "OpeningHoursSpecification",
        "dayOfWeek": [
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
          "Sunday"
        ],
        "opens": "07:00",
        "closes": "20:00"
      }
    },
    extraView: {
      "@context": "https://schema.org",
      "@type": "Service",
      "name": "DSTV Extra View Installation Sandton",
      "provider": baseSchema,
      "areaServed": {
        "@type": "City",
        "name": "Sandton"
      },
      "description": "Professional DSTV Extra View installation allowing multiple TV viewing with synchronized recording capabilities.",
      "offers": {
        "@type": "Offer",
        "availability": "https://schema.org/InStock",
        "priceSpecification": {
          "@type": "PriceSpecification",
          "priceCurrency": "ZAR"
        }
      }
    },
    contact: {
      "@context": "https://schema.org",
      "@type": "ContactPage",
      "name": "Contact DSTV Pro Installations Sandton",
      "mainEntity": {
        "@type": "LocalBusiness",
        ...baseSchema
      },
      "potentialAction": {
        "@type": "CommunicateAction",
        "target": {
          "@type": "EntryPoint",
          "urlTemplate": "tel:+27787294594"
        }
      }
    },
    maintenance: {
      "@context": "https://schema.org",
      "@type": "Service",
      "name": "DSTV Maintenance Services",
      "provider": baseSchema,
      "serviceType": "DSTV Maintenance",
      "description": "Professional DSTV maintenance and repair services including signal optimization and hardware checks."
    }
  };

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(schemas[page])}
      </script>
    </Helmet>
  );
} 