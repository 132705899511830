import { ArrowRight, Tv, Speaker, Radio, Tool, Shield, CheckCircle2 } from 'lucide-react';
import { Link } from '../components/Link';

export default function TVInstallationPage() {
  return (
    <div className="pt-20 bg-gradient-to-b from-gray-900 to-gray-800">
      {/* Header Section */}
      <div className="bg-gray-900/50 backdrop-blur text-white py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 className="text-4xl font-bold mb-6">TV & Sound Installation Services</h1>
          <p className="text-xl text-gray-300">
            Transform your home entertainment experience with our professional TV mounting and sound system installation services. 
            From wall mounting your TV to setting up immersive home theater systems, we deliver excellence in every installation.
          </p>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        {/* Services Grid with Images */}
        <div className="grid md:grid-cols-3 gap-8 mb-16">
          {[
            {
              image: "/images/TV Mounting.jpg",
              title: "TV Wall Mounting",
              description: "Professional mounting of LCD/Plasma TVs with clean cable management and perfect positioning"
            },
            {
              image: "/images/Home Theatre.jpg",
              title: "Home Theater Setup",
              description: "Complete home theater installation with surround sound system configuration"
            },
            {
              image: "/images/TV Installation.jpg",
              title: "TV Points Installation",
              description: "Additional TV point installation for multi-room entertainment solutions"
            }
          ].map((service) => (
            <div key={service.title} className="bg-[#2d3748] rounded-xl overflow-hidden shadow-lg">
              <div className="relative h-48">
                <img
                  src={service.image}
                  alt={service.title}
                  className="w-full h-full object-cover"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-[#1f2937] to-transparent"></div>
              </div>
              <div className="p-6">
                <h3 className="text-xl font-bold text-white mb-2">{service.title}</h3>
                <p className="text-gray-300">{service.description}</p>
              </div>
            </div>
          ))}
        </div>

        {/* Features Section */}
        <div className="mb-16">
          <div className="text-center mb-16">
            <h2 className="text-3xl font-bold text-white mb-4">Our Installation Services</h2>
            <p className="text-gray-300 text-lg">
              Expert installation services by certified technicians
            </p>
          </div>

          <div className="grid md:grid-cols-3 gap-8">
            {[
              {
                icon: <Tv className="h-8 w-8" />,
                title: "TV Mounting",
                description: "Professional TV wall mounting with concealed wiring and perfect leveling"
              },
              {
                icon: <Speaker className="h-8 w-8" />,
                title: "Sound Systems",
                description: "Complete home theater and sound system installation and calibration"
              },
              {
                icon: <Radio className="h-8 w-8" />,
                title: "TV Points",
                description: "Additional TV point installation with signal amplification where needed"
              }
            ].map((feature) => (
              <div key={feature.title} className="bg-[#2d3748] p-8 rounded-2xl shadow-lg">
                <div className="text-pink-500 mb-4">{feature.icon}</div>
                <h3 className="text-xl font-bold text-white mb-4">{feature.title}</h3>
                <p className="text-gray-300">{feature.description}</p>
              </div>
            ))}
          </div>
        </div>

        {/* Installation Process */}
        <div className="bg-[#2d3748] rounded-2xl p-8 shadow-xl mb-16">
          <h2 className="text-3xl font-bold text-white mb-8">Our Installation Process</h2>
          <div className="grid md:grid-cols-2 gap-8">
            <div className="space-y-6">
              {[
                {
                  step: "1",
                  title: "Site Assessment",
                  description: "Evaluate wall structure, wiring requirements, and optimal positioning"
                },
                {
                  step: "2",
                  title: "Installation Planning",
                  description: "Determine mounting height, viewing angle, and cable management strategy"
                },
                {
                  step: "3",
                  title: "Professional Installation",
                  description: "Expert mounting and system setup with attention to detail"
                },
                {
                  step: "4",
                  title: "Testing & Calibration",
                  description: "Complete system testing and sound calibration for optimal performance"
                }
              ].map((step) => (
                <div key={step.title} className="flex items-start">
                  <div className="bg-pink-500 text-white w-8 h-8 rounded-full flex items-center justify-center flex-shrink-0 mt-1 mr-4">
                    {step.step}
                  </div>
                  <div>
                    <h3 className="text-xl font-semibold text-white mb-2">{step.title}</h3>
                    <p className="text-gray-300">{step.description}</p>
                  </div>
                </div>
              ))}
            </div>
            <div className="bg-[#1f2937] p-8 rounded-xl">
              <h3 className="text-2xl font-bold text-white mb-6">Service Guarantee</h3>
              <ul className="space-y-4">
                {[
                  "Professional installation by certified technicians",
                  "Quality mounting brackets and hardware",
                  "Neat cable management",
                  "System optimization and testing",
                  "30-day installation warranty",
                  "After-service support"
                ].map((item) => (
                  <li key={item} className="flex items-center text-gray-300">
                    <CheckCircle2 className="h-5 w-5 text-pink-500 mr-3 flex-shrink-0" />
                    <span>{item}</span>
                  </li>
                ))}
              </ul>
              <p className="mt-6 text-gray-300">
                Trust your TV and sound installation to the same team that provides excellent{' '}
                <Link href="/" className="text-pink-400 hover:text-pink-300">DSTV installation</Link> services.
              </p>
            </div>
          </div>
        </div>

        {/* CTA Section */}
        <div className="text-center">
          <h2 className="text-3xl font-bold text-white mb-6">Ready to Transform Your Entertainment Setup?</h2>
          <p className="text-xl text-gray-300 mb-8">
            Get professional TV mounting and sound system installation services today
          </p>
          <Link
            href="/contact"
            className="inline-flex items-center bg-pink-500 text-white px-8 py-4 rounded-full hover:bg-pink-600 transition-all duration-200 shadow-lg hover:shadow-pink-500/25"
          >
            Book Installation
            <ArrowRight className="ml-2 h-5 w-5" />
          </Link>
        </div>
      </div>
    </div>
  );
} 