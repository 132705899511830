import { ArrowRight, Tv, Settings, CheckCircle2, HelpCircle, Users, Zap, Timer } from 'lucide-react';
import { Link } from '../components/Link';
import { SEO } from '../components/SEO';
import { SchemaMarkup } from '../components/SchemaMarkup';

export default function ExtraViewPage() {
  return (
    <>
      <SEO 
        title="DSTV Extra View Installation Sandton | Multi-Room TV Setup"
        description="Professional DSTV Extra View installation in Sandton. Watch different channels on multiple TVs simultaneously. Expert setup, same-day service."
        canonicalUrl="/extra-view/"
      />
      <SchemaMarkup type="Service" page="extraView" />
      <div className="pt-20 bg-[#1f2937]">
        {/* Header Section */}
        <div className="bg-[#1a222e] text-white py-16">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <h1 className="text-4xl font-bold mb-6">DStv ExtraView Installation</h1>
            <p className="text-xl text-gray-300">
              Worn out from the unremitting battle for the TV remote? Discover DSTV Extra View, 
              a novel remedy for elevated TV experiences and schedule freedom. Choose a conflict-free 
              viewing environment for your home.
            </p>
          </div>
        </div>

        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
          {/* Hero Image Section - Updated to be half-width */}
          <div className="grid md:grid-cols-2 gap-12 items-center mb-16">
            <div className="relative">
              <img
                src="/images/DSTV-Installation-1.png"
                alt="DSTV Installation Services"
                className="rounded-2xl shadow-2xl w-full h-auto object-cover"
              />
              <div className="absolute inset-0 rounded-2xl bg-gradient-to-r from-pink-500/20 to-transparent"></div>
            </div>
            <div>
              <h2 className="text-2xl font-bold text-white mb-4">Transform Your Viewing Experience</h2>
              <p className="text-gray-300">
                Established thoroughly by our accredited <Link href="/" className="text-pink-400 hover:text-pink-300">DSTV installers in Sandton</Link>, DSTV Extra View allows concurrent 
                viewing on separate televisions, proposing a unique entertainment customization in homes 
                with numerous TVs. Additionally, its capacity for multiple recordings guarantees you won't 
                skip any much-loved content.
              </p>
            </div>
          </div>

          {/* Features Section */}
          <div className="mb-16">
            <div className="text-center mb-16">
              <h2 className="text-3xl font-bold text-white mb-4">Benefits of ExtraView</h2>
              <p className="text-gray-300 text-lg">
                Experience entertainment freedom with multiple viewing options
              </p>
            </div>

            <div className="grid md:grid-cols-3 gap-8">
              {[
                {
                  icon: <Users className="h-8 w-8" />,
                  title: "End Remote Wars",
                  description: "No more fighting over the remote - everyone watches their preferred shows simultaneously"
                },
                {
                  icon: <Zap className="h-8 w-8" />,
                  title: "Smart Investment",
                  description: "One subscription, multiple TVs - maximize your entertainment value"
                },
                {
                  icon: <Timer className="h-8 w-8" />,
                  title: "Record & Watch",
                  description: "Record your favorite shows while watching others - never miss a moment"
                }
              ].map((feature) => (
                <div key={feature.title} className="bg-[#2d3748] p-8 rounded-2xl shadow-lg">
                  <div className="text-pink-500 mb-4">{feature.icon}</div>
                  <h3 className="text-xl font-bold text-white mb-4">{feature.title}</h3>
                  <p className="text-gray-300">{feature.description}</p>
                </div>
              ))}
            </div>
          </div>

          {/* Installation Process */}
          <div className="bg-[#2d3748] rounded-2xl p-8 shadow-xl mb-16">
            <h2 className="text-3xl font-bold text-white mb-8">Professional Installation Process</h2>
            <div className="grid md:grid-cols-2 gap-8">
              <div className="space-y-6">
                {[
                  {
                    step: "1",
                    title: "Home Assessment",
                    description: "We evaluate your home layout to determine optimal TV points and cable routing"
                  },
                  {
                    step: "2",
                    title: "ExtraView Setup",
                    description: "Professional installation of additional decoders and smart card linking"
                  },
                  {
                    step: "3",
                    title: "System Integration",
                    description: "Expert configuration ensuring all TVs work seamlessly together"
                  },
                  {
                    step: "4",
                    title: "User Guide",
                    description: "Comprehensive demonstration of all ExtraView features and functionality"
                  }
                ].map((step) => (
                  <div key={step.title} className="flex items-start">
                    <div className="bg-pink-500 text-white w-8 h-8 rounded-full flex items-center justify-center flex-shrink-0 mt-1 mr-4">
                      {step.step}
                    </div>
                    <div>
                      <h3 className="text-xl font-semibold text-white mb-2">{step.title}</h3>
                      <p className="text-gray-300">{step.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div className="bg-[#1f2937] p-8 rounded-xl">
                <h3 className="text-2xl font-bold text-white mb-6">What's Included</h3>
                <ul className="space-y-4">
                  {[
                    "Professional installation by certified technicians",
                    "All necessary cables and connectors",
                    "Signal quality optimization",
                    "Complete system testing",
                    "User training and setup guide",
                    <>30-day installation warranty with optional <Link href="/maintenance" className="text-pink-400 hover:text-pink-300">ongoing maintenance service</Link></>
                  ].map((item) => (
                    <li key={typeof item === 'string' ? item : 'warranty'} className="flex items-center text-gray-300">
                      <CheckCircle2 className="h-5 w-5 text-pink-500 mr-3 flex-shrink-0" />
                      <span>{item}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          {/* CTA Section */}
          <div className="text-center">
            <h2 className="text-3xl font-bold text-white mb-6">Ready to End TV Conflicts?</h2>
            <p className="text-xl text-gray-300 mb-8">
              Transform your home entertainment experience today with DSTV ExtraView
            </p>
            <Link
              href="/contact"
              className="inline-flex items-center bg-pink-500 text-white px-8 py-4 rounded-full hover:bg-pink-600 transition-all duration-200 shadow-lg hover:shadow-pink-500/25"
            >
              Book Your Installation
              <ArrowRight className="ml-2 h-5 w-5" />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
