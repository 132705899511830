import { Link } from '../components/Link';

function PrivacyPolicyPage() {
  return (
    <div className="pt-20 bg-[#1f2937]">
      {/* Header Section */}
      <div className="bg-[#1a222e] text-white py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 className="text-4xl font-bold mb-6">Privacy Policy</h1>
          <p className="text-xl text-gray-300">
            Your privacy is important to us. Learn how we collect, use, and protect your personal information.
          </p>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <div className="text-gray-200 max-w-4xl mx-auto space-y-8">
          <section className="bg-gray-800/50 p-8 rounded-lg">
            <h2 className="text-2xl font-bold text-white mb-4">Who We Are</h2>
            <p className="text-lg leading-relaxed">
              DSTV Pro Installations operates https://www.dstvinstallationsandton.co.za. We are a professional DSTV installation service provider 
              connecting customers with qualified installers in the Sandton area and surroundings.
            </p>
          </section>

          <section className="bg-gray-800/50 p-8 rounded-lg">
            <h2 className="text-2xl font-bold text-white mb-4">Information We Collect</h2>
            <div className="space-y-4">
              <p className="text-lg leading-relaxed">We collect the following types of information:</p>
              
              <div className="ml-6">
                <h3 className="text-xl font-semibold text-white mb-2">Personal Information:</h3>
                <ul className="list-disc pl-8 space-y-2 text-lg">
                  <li>Name and surname</li>
                  <li>Email address</li>
                  <li>Physical installation address</li>
                  <li>Contact telephone numbers</li>
                  <li>Type of service requested</li>
                  <li>Preferred installation date and time</li>
                </ul>
              </div>

              <div className="ml-6">
                <h3 className="text-xl font-semibold text-white mb-2">Technical Information:</h3>
                <ul className="list-disc pl-8 space-y-2 text-lg">
                  <li>IP address</li>
                  <li>Browser type and version</li>
                  <li>Device information</li>
                  <li>Pages visited on our website</li>
                  <li>Time and date of visits</li>
                </ul>
              </div>
            </div>
          </section>

          <section className="bg-gray-800/50 p-8 rounded-lg">
            <h2 className="text-2xl font-bold text-white mb-4">How We Use Your Information</h2>
            <p className="text-lg leading-relaxed mb-4">We use your information for the following purposes:</p>
            <ul className="list-disc pl-8 space-y-2 text-lg">
              <li>To connect you with qualified DSTV installers in your area</li>
              <li>To process and fulfill your installation or service requests</li>
              <li>To communicate with you about your service booking</li>
              <li>To send you important service updates and notifications</li>
              <li>To improve our website and services</li>
              <li>To comply with legal obligations</li>
              <li>To respond to your inquiries and support requests</li>
            </ul>
          </section>

          <section className="bg-gray-800/50 p-8 rounded-lg">
            <h2 className="text-2xl font-bold text-white mb-4">Information Sharing and Disclosure</h2>
            <p className="text-lg leading-relaxed mb-4">We may share your information with:</p>
            <ul className="list-disc pl-8 space-y-2 text-lg">
              <li>Verified DSTV installers in our network who will provide the requested service</li>
              <li>Service providers who assist in operating our website and conducting business</li>
              <li>Law enforcement or other authorities when legally required</li>
            </ul>
            <p className="text-lg leading-relaxed mt-4">
              We do not sell, trade, or rent your personal information to third parties for marketing purposes.
            </p>
          </section>

          <section className="bg-gray-800/50 p-8 rounded-lg">
            <h2 className="text-2xl font-bold text-white mb-4">Data Security</h2>
            <p className="text-lg leading-relaxed mb-4">
              We implement appropriate security measures to protect your personal information, including:
            </p>
            <ul className="list-disc pl-8 space-y-2 text-lg">
              <li>Secure SSL encryption for all data transmission</li>
              <li>Regular security assessments and updates</li>
              <li>Limited access to personal information by authorized personnel only</li>
              <li>Secure data storage systems</li>
              <li>Regular staff training on data protection</li>
            </ul>
          </section>

          <section className="bg-gray-800/50 p-8 rounded-lg">
            <h2 className="text-2xl font-bold text-white mb-4">Your Rights</h2>
            <p className="text-lg leading-relaxed mb-4">Under POPIA, you have the right to:</p>
            <ul className="list-disc pl-8 space-y-2 text-lg">
              <li>Access your personal information</li>
              <li>Correct inaccurate personal information</li>
              <li>Request deletion of your personal information</li>
              <li>Object to the processing of your personal information</li>
              <li>Lodge a complaint with the Information Regulator</li>
              <li>Withdraw consent for processing your information</li>
            </ul>
          </section>

          <section className="bg-gray-800/50 p-8 rounded-lg">
            <h2 className="text-2xl font-bold text-white mb-4">Cookie Policy</h2>
            <p className="text-lg leading-relaxed mb-4">Our website uses cookies for:</p>
            <ul className="list-disc pl-8 space-y-2 text-lg">
              <li>Essential website functionality and navigation</li>
              <li>Analytics to improve our service (Google Analytics)</li>
              <li>Session management and security</li>
              <li>User preference storage</li>
            </ul>
            <p className="text-lg leading-relaxed mt-4">
              You can control cookie settings through your browser preferences.
            </p>
          </section>

          <section className="bg-gray-800/50 p-8 rounded-lg">
            <h2 className="text-2xl font-bold text-white mb-4">Changes to This Policy</h2>
            <p className="text-lg leading-relaxed">
              We may update this privacy policy from time to time. Any changes will be posted on this page with an updated revision date.
              Continued use of our website after such changes constitutes acceptance of the updated policy.
            </p>
          </section>

          <section className="bg-gray-800/50 p-8 rounded-lg">
            <h2 className="text-2xl font-bold text-white mb-4">Contact Us</h2>
            <p className="text-lg leading-relaxed">
              For any privacy-related questions or to exercise your rights, please contact our Information Officer at:{' '}
              <Link href="mailto:info@dstvinstallationsandton.co.za" className="text-pink-400 hover:text-pink-300 font-medium">
                info@dstvinstallationsandton.co.za
              </Link>
            </p>
            <p className="text-lg leading-relaxed mt-4">
              Physical Address: Sandton, Johannesburg<br />
              Phone: +27 78 729 4594
            </p>
          </section>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicyPage; 