import { Facebook, Twitter, Instagram, Youtube, Phone, Mail, MapPin } from 'lucide-react';
import { Link } from './Link';

interface ScrollToTopLinkProps {
  href: string;
  children: React.ReactNode;
  className?: string;
}

function ScrollToTopLink({ href, children, className }: ScrollToTopLinkProps) {
  const handleClick = () => {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 100);
  };

  return (
    <a href={href} className={className} onClick={handleClick}>
      {children}
    </a>
  );
}

export default function Footer() {
  const serviceLinks = [
    { href: "/services", label: "Services" },
    { href: "/extra-view", label: "Extra View" },
    { href: "/maintenance", label: "Maintenance" },
    { href: "/tv-installation", label: "TV Installation" },
    { href: "/cctv", label: "CCTV" },
    { href: "/commercial", label: "Commercial" },
    { href: "/troubleshooting", label: "Troubleshooting" },
  ];

  const legalLinks = [
    { href: "/privacy-policy", label: "Privacy Policy" },
    { href: "/terms", label: "Terms & Conditions" },
  ];

  return (
    <footer className="relative">
      {/* Background Image with Overlay */}
      <div 
        className="absolute inset-0 z-0" 
        style={{
          backgroundImage: 'url("/images/DSTV-Background.jpg")',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <div className="absolute inset-0 bg-gradient-to-b from-[#1f2937]/70 via-[#0f172a]/80 to-[#020617]/90"></div>
      </div>

      <div className="relative z-10 h-px bg-gradient-to-r from-transparent via-pink-500/20 to-transparent" />
      
      <div className="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          {/* Company Info with Logo */}
          <div className="space-y-4">
            <img 
              src="/images/DSTV-PRO-INSTALLATION-LOGO-1 (2).png" 
              alt="DSTV Pro Installations Logo" 
              className="h-16 w-auto"
            />
            <p className="text-gray-300">
              Professional DSTV installation services in Sandton and surrounding areas.
            </p>
          </div>

          {/* Quick Links */}
          <div>
            <h3 className="text-xl font-bold text-white mb-4">Quick Links</h3>
            <ul className="space-y-2">
              {serviceLinks.map((link) => (
                <li key={link.href}>
                  <Link href={link.href} className="text-gray-300 hover:text-pink-400">
                    {link.label}
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          {/* Legal Links */}
          <div>
            <h3 className="text-xl font-bold text-white mb-4">Legal</h3>
            <ul className="space-y-2">
              {legalLinks.map((link) => (
                <li key={link.href}>
                  <Link href={link.href} className="text-gray-300 hover:text-pink-400">
                    {link.label}
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          {/* Contact Info */}
          <div>
            <h3 className="text-xl font-bold text-white mb-4">Contact</h3>
            <ul className="space-y-2">
              <li className="flex items-center text-gray-300">
                <Phone className="h-4 w-4 mr-2 text-pink-500" />
                <ScrollToTopLink href="tel:+27787294594" className="hover:text-pink-400">+27 78 729 4594</ScrollToTopLink>
              </li>
              <li className="flex items-center text-gray-300">
                <Mail className="h-4 w-4 mr-2 text-pink-500" />
                <ScrollToTopLink href="mailto:info@dstvinstallationsandton.co.za" className="hover:text-pink-400 break-all">
                  info@dstvinstallationsandton.co.za
                </ScrollToTopLink>
              </li>
              <li className="flex items-center text-gray-300">
                <MapPin className="h-4 w-4 mr-2 text-pink-500" />
                <span>Sandton, Johannesburg</span>
              </li>
              <li className="flex items-center text-gray-300 mt-4">
                <ScrollToTopLink 
                  href="https://www.facebook.com/101120769700149?ref=embed_page" 
                  className="text-gray-300 hover:text-pink-400"
                >
                  <Facebook className="h-6 w-6" />
                </ScrollToTopLink>
              </li>
            </ul>
          </div>
        </div>
        <div className="mt-8 pt-8 border-t border-gray-800/30 text-center text-gray-300">
          <p>© {new Date().getFullYear()} DSTV Pro Installations. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
}
